import React from 'react'
import Form from '../../components/PSP/PSPForm/Form'
import Footer from '../../components/Footer/Footer'
import IndexLayout from '../../layouts'
import { LocaleTypes } from '../../data/locales';


interface PSPProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const formIndustries: React.FC<PSPProps> = ({location, pathContext}) => {
  console.log(location)
  const content = () => (
    <>
      <Form location={location} />
      <Footer theme="primary" />
    </>
  )
  return (
    <IndexLayout navbarTheme="default" locale={pathContext.localeCode} location={location} render={content} />
  )
}

export default formIndustries